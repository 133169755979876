<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Courier</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row ma-0 pa-0">
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              outlined
              dense
              required
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.system_code"
              label="System code"
              outlined
              dense
              clearable
              :error-messages="system_codeErrors"
              @input="$v.formData.system_code.$touch()"
              @blur="$v.formData.system_code.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4" v-if="serverData.organizations">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-autocomplete>
          </div>

          <div
            class="h-75px col-12 col-sm-6"
            :class="serverData.organizations ? 'col-md-4' : ''"
          >
            <v-autocomplete
              dense
              outlined
              v-model="formData.warehouse_id"
              :items="serverData.warehouses"
              item-name="text"
              item-value="index"
              label="Warehouse"
              clearable
              :error-messages="warehouse_idErrors"
              @input="$v.formData.warehouse_id.$touch()"
              @blur="$v.formData.warehouse_id.$touch()"
            ></v-autocomplete>
          </div>
          <div
            class="h-75px col-12 col-sm-6"
            :class="serverData.organizations ? 'col-md-4' : ''"
          >
            <v-select
              v-model="formData.origin_country_id"
              label="Origin country id"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-file-input
              class="col-12"
              v-model="formData.image"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Image"
              clearable
              :error-messages="imageErrors"
              @input="$v.formData.image.$touch()"
              @blur="$v.formData.image.$touch()"
            ></v-file-input>
          </div>

          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.url"
              label="Url"
              clearable
              outlined
              dense
              :error-messages="urlErrors"
              @input="$v.formData.url.$touch()"
              @blur="$v.formData.url.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-autocomplete
              dense
              outlined
              v-model="formData.type_id"
              :items="serverData.types"
              item-name="text"
              item-value="index"
              label="Type"
              clearable
              :error-messages="type_idErrors"
              @input="$v.formData.type_id.$touch()"
              @blur="$v.formData.type_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.api_key"
              label="Api key"
              clearable
              outlined
              dense
              @input="$v.formData.api_key.$touch()"
              @blur="$v.formData.api_key.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.api_secret"
              label="Api secret"
              clearable
              outlined
              dense
              @input="$v.formData.api_secret.$touch()"
              @blur="$v.formData.api_secret.$touch()"
            ></v-text-field>
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.awb_create_status"
              label="AWB create status"
              item-text="text"
              item-value="index"
              :items="serverData.order_statuses"
              clearable
              outlined
              dense
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.awb_update_status"
              label="AWB update status"
              item-text="text"
              item-value="index"
              :items="serverData.order_statuses"
              clearable
              outlined
              dense
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.awb_cancel_status"
              label="AWB cancel status"
              item-text="text"
              item-value="index"
              :items="serverData.order_statuses"
              clearable
              outlined
              dense
            ></v-select>
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.delivery_no_prefix"
              label="Delivery no Prefix"
              clearable
              outlined
              dense
              @input="$v.formData.delivery_no_prefix.$touch()"
              @blur="$v.formData.delivery_no_prefix.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.delivery_no_length"
              label="Delivery no length"
              clearable
              outlined
              dense
              :error-messages="delivery_no_lengthErrors"
              @input="$v.formData.delivery_no_length.$touch()"
              @blur="$v.formData.delivery_no_length.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.delivery_no_count"
              label="Delivery no count"
              type="Number"
              clearable
              outlined
              dense
              :error-messages="delivery_no_countErrors"
              @input="$v.formData.delivery_no_count.$touch()"
              @blur="$v.formData.delivery_no_count.$touch()"
              @change="
                () => validateMinValue('delivery_no_count', 0, 'formData')
              "
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.delivery_no_from"
              label="Delivery no from"
              clearable
              outlined
              dense
              :error-messages="delivery_no_fromErrors"
              @input="$v.formData.delivery_no_from.$touch()"
              @blur="$v.formData.delivery_no_from.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.delivery_no_to"
              label="Delivery no to"
              clearable
              outlined
              dense
              :error-messages="delivery_no_toErrors"
              @input="$v.formData.delivery_no_to.$touch()"
              @blur="$v.formData.delivery_no_to.$touch()"
            ></v-text-field>
          </div>
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {
  required,
  url,
  requiredIf,
  numeric,
  // maxLength,
  between,
  // minLength,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      formData: {
        name: { required },
        image: { required },
        system_code: { required },
        warehouse_id: { required },
        url: { url },
        api_key: {},
        api_secret: {},
        type_id: { required },
        delivery_no_prefix: {},
        delivery_no_length: { numeric },
        delivery_no_from: { numeric },
        delivery_no_to: { numeric },
        delivery_no_count: { numeric, between: between(0, 1) },
        organization_id: {
          required: requiredIf(() => {
            return !!this.serverData.organizations;
          }),
        },
      },
    };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: {},
    formData: {
      image: null,
      system_code: null,
      name: null,
      url: null,
      api_key: null,
      api_secret: null,
      delivery_no_prefix: null,
      delivery_no_length: null,
      delivery_no_from: null,
      delivery_no_to: null,
      delivery_no_count: null,
      origin_country_id: null,
      warehouse_id: null,
      type_id: null,
      awb_create_status: null,
      awb_update_status: null,
      awb_cancel_status: null,
      organization_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/shipping/couriers/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] != "image") || item[1] == 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] == "image") {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/shipping/couriers/store", data)
          .then(() => {
            this.pageLoader(false);
            Swal.fire({
              title: "Created",
              text: "New courier has been added successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        image: null,
        system_code: null,
        name: null,
        url: null,
        api_key: null,
        api_secret: null,
        delivery_no_prefix: null,
        delivery_no_length: null,
        delivery_no_from: null,
        delivery_no_to: null,
        delivery_no_count: null,
        origin_country_id: null,
        warehouse_id: null,
        type_id: null,
        awb_create_status: null,
        awb_update_status: null,
        awb_cancel_status: null,
        organization_id: null,
      };
    },
  },
  computed: {
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    imageErrors() {
      return this.handleFormValidation("image", this);
    },
    system_codeErrors() {
      return this.handleFormValidation("system_code", this);
    },
    urlErrors() {
      return this.handleFormValidation("url", this);
    },
    delivery_no_fromErrors() {
      return this.handleFormValidation("delivery_no_from", this);
    },
    delivery_no_lengthErrors() {
      return this.handleFormValidation("delivery_no_length", this);
    },
    delivery_no_toErrors() {
      return this.handleFormValidation("delivery_no_to", this);
    },
    delivery_no_countErrors() {
      return this.handleFormValidation("delivery_no_count", this);
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    type_idErrors() {
      return this.handleFormValidation("type_id", this);
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
