<template>
  <div class="ma-0 row mb-3">
    <div class="col position-relative">
      <div class="input-group-bordered rounded py-4">
        <div class="my-2 my-md-4 row mx-1">
          <slot></slot>
        </div>
      </div>
      <div class="position-absolute px-2 form-group-label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  props: { label: { type: String, required: true } },
};
</script>
<style lang="scss">
.input-group-bordered {
  border: 1px solid #dcdfe8 !important;
}
.form-group-label {
  top: 3px;
  left: 30px;
  background: white;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}
</style>
